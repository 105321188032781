import React from "react";
import { Col, Row } from "react-bootstrap";

export function DSLoader(props) {
  return (
    <Row>
      <Col>
        <div
          className="loader-wrapper"
          style={{
            width: props.width ? props.width + "px" : "",
            height: props.height ? props.height + "px" : "",
            left: props.width ? "calc(50% - " +
              (
                props.width / 2
              ) +
              "px)" : ""
          }}
        >
          <div className="logo" />
          <div className="loader">
            <span />
            <span />
            <span />
            <span />
          </div>
        </div>
      </Col>
    </Row>
  );
}
